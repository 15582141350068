import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useLazyProfileQuery } from "../../../../redux/api/user/userAPI";
import {
  selectAuth,
  setIsNewUser,
} from "../../../../redux/features/auth/auth-slice";
import { selectProfile } from "../../../../redux/features/user/user-slice";
import { EPrivileges } from "../../enums/privileges";
import { UserRole } from "../../enums/user-roles";
import { AppRoute } from "../../interfaces/app-routes";
import { IHttpError } from "../../interfaces/http-errror";
import SpinnerModal from "../../ui-elements/spinner/spinner";
import isMobileNumberVerified from "../../utils/isMobileNumberVerified";

interface IProps {
  role: UserRole;
}

const PrivateRoute = (props: IProps) => {
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [getProfile, { isFetching, isLoading }] = useLazyProfileQuery();
  const profile = useSelector(selectProfile);

  useEffect(() => {
    getProfile()
      .unwrap()
      .catch((error: IHttpError) => {
        if (isMobileNumberVerified(error)) {
          if (auth.isNewUser) {
            const mobileNumber = auth.mobileNumber;
            dispatch(setIsNewUser({ isNewUser: false, mobileNumber: "" }));
            navigate(AppRoute.USER_MOBILE_NUMBER_VERIFICATION, {
              state: {
                number: mobileNumber,
                whereTo: "dashboard",
              },
            });
          } else {
            navigate(AppRoute.VERIFY_MOBILE_NUMBER);
          }
        }
      });
  }, []);

  useEffect(() => {
    if (!auth.token) {
      navigate(AppRoute.LOGIN);
      return;
    }

    if (isLoading || isFetching) {
      return;
    }

    if (
      auth.privileges?.includes(
        EPrivileges.ONLY_EXTERNAL_GENERATION_READ_PRIVILEGE
      ) &&
      auth.privileges?.includes(EPrivileges.ROLE_ADMIN)
    ) {
      navigate(AppRoute.ADMIN_SOLAR_PLANTS);
    }

    if (profile && profile.role !== props.role) {
      if (profile.role === UserRole.ROLE_ADMIN) {
        navigate(AppRoute.ADMIN);
      } else if (
        profile.role === UserRole.ROLE_USER ||
        profile.role === UserRole.ROLE_VIEW_ONLY_USER
      ) {
        navigate(AppRoute.DASHBOARD);
      } else {
        navigate(AppRoute.UNAUTHORIZED);
      }
      return;
    }
  }, [auth.token, profile, isLoading, isFetching]);

  return (
    <>
      <SpinnerModal show={isFetching || isLoading} />
      {!isFetching && !isLoading && <Outlet />}
    </>
  );
};

export default PrivateRoute;
